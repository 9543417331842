import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  useGetPtcByIdQuery,
  useSetClaimedMutation,
} from "../../store/ptc/ptcApiSlice";
import Countdown from "react-countdown";
import {
  useAddUserTokensMutation,
  useLevelUpUserMutation,
  useIncrementCountMutation,
} from "../../store/user/userApiSlice";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import Banner2 from "../shared/Banner2";

const PtcIframe = () => {
  const [captchaVal, setCaptchaVal] = useState("");
  const [error, setError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const clockRef = useRef();

  const getData = useCallback(useGetPtcByIdQuery, [
    captchaVal,
    error,
    isModalOpen,
  ]);
  const { data } = getData(Number(window.location.href.split("/")[4]));

  const [addUserTokens] = useAddUserTokensMutation();
  const [levelUpUser] = useLevelUpUserMutation();
  const [setClaimed] = useSetClaimedMutation();
  const [incrementCount] = useIncrementCountMutation();

  const checkDocumentFocus = () => {
    if (document.hasFocus()) {
      clockRef.current.start();
    } else {
      clockRef.current.pause();
    }
  };

  const setReward = async () => {
    const tokensResp = await addUserTokens({
      tokens: data?.tokensReward,
      type: "ptc",
    });
    const levelUpResp = await levelUpUser({ exp: 5 });
    const claimedResp = await setClaimed({
      ptcId: Number(window.location.href.split("/")[4]),
      lastTaken: new Date().toLocaleString("en-US"),
    });
    const incrResp = await incrementCount({ type: "ptc" });
    if (
      tokensResp.data &&
      levelUpResp.data &&
      claimedResp.data &&
      incrResp.data
    ) {
      window.open(data?.link, "_blank").focus();
      window.location.href = "https://rocket-coin.online/ptc";
    }
    console.log({ tokensResp, levelUpResp, claimedResp, incrResp });
  };

  const submitCaptcha = () => {
    if (validateCaptcha(captchaVal) === true) {
      setReward();
    } else {
      setError(true);
    }
  };

  const countDownFunc = ({ seconds, completed }) => {
    if (completed) {
      setIsModalOpen(true);
    } else {
      return <span className="count-down">{seconds}</span>;
    }
  };

  useEffect(() => {
    setInterval(checkDocumentFocus, 500);
  }, []);

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const codes = [
    [2284931, 385201],
    [2284932, 385202],
    [2284933, 385203],
    [2284934, 385204],
    [2284935, 385205],
    [2284936, 385206],
    [2284937, 385207],
    [2284938, 385208],
    [2284941, 385209],
    [2284942, 385210],
    [2284944, 385211],
    [2284945, 385212],
    [2284946, 385213],
    [2284948, 385214],
    [2284949, 385215],
  ];
  const codesMobile = [
    [2284931, 385100],
    [2284932, 385101],
    [2284933, 385102],
    [2284934, 385103],
    [2284935, 385104],
    [2284936, 385105],
    [2284937, 385106],
    [2284938, 385107],
    [2284941, 385108],
    [2284942, 385109],
    [2284944, 385110],
    [2284945, 385111],
    [2284946, 385112],
    [2284948, 385113],
    [2284949, 385114],
  ];

  return (
    <main>
      <div className="ptc-counter-container">
        <a href="https://rocket-coin.online/ptc">
          <Logo />
        </a>
        <div className="banner banner728 banner-profile desktop-banner">
          <iframe
            title="banner"
            data-aa={codes[Number(window.location.href.split("/")[4])]}
            src="//ad.a-ads.com/2275838?size=728x90"
          ></iframe>
        </div>
        <div className="banner banner728 banner-profile mobile-banner">
          <iframe
            title="banner"
            data-aa={codesMobile[Number(window.location.href.split("/")[4])]}
            src="//ad.a-ads.com/2275838?size=728x90"
          ></iframe>
        </div>
        <Countdown
          date={
            new Date().getTime() +
            1000 * Number(window.location.href.split("/")[5])
          }
          renderer={countDownFunc}
          ref={clockRef}
        />
      </div>

      <div className="frame-cont" onBlur={() => console.log("focus")}>
        <iframe
          title={`ptc-${data?.id}`}
          src={data?.link}
          frameborder="0"
          className="ptc-iframe"
        ></iframe>
      </div>
      <div className="ptc-bottom-container">
        <div className="banner banner728 banner-profile desktop-banner">
          <Banner2 data={codes[Number(window.location.href.split("/")[4])]} />
        </div>
        <div className="banner banner728 banner-profile mobile-banner">
          <Banner2
            data={codesMobile[Number(window.location.href.split("/")[4])]}
          />
        </div>
      </div>
      <div className={`captcha-modal ${isModalOpen && "modal-active"}`}>
        <div className="captcha-modal-inner">
          <LoadCanvasTemplateNoReload />
          <input
            type="text"
            value={captchaVal}
            onChange={(e) => setCaptchaVal(e.target.value)}
            autocomplete="one-time-code"
            required
            className="captcha-input"
            placeholder="Введите значение"
          />
          {error && (
            <p className="captcha-label-error">Значения не совпадают</p>
          )}
          <div className="banner banner120 banner-ptc-modal">
            <iframe
              title="banner"
              data-aa="2256203"
              src="//ad.a-ads.com/2256203?size=120x60"
            ></iframe>
          </div>
          <button className="captcha-btn btn-task" onClick={submitCaptcha}>
            Подтвердить
          </button>
        </div>
      </div>
    </main>
  );
};

export default PtcIframe;
