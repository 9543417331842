import { apiSlice } from "../api/apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      query: () => "/user/info",
    }),
    getAllUsers: builder.query({
      query: (offset) => `/user/all/${offset}`,
    }),
    updateUserInfo: builder.mutation({
      query: (credentials) => ({
        url: "/user/edit",
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    addUserTokens: builder.mutation({
      query: (credentials) => ({
        url: "/user/tokens",
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    addUserTokensDif: builder.mutation({
      query: (credentials) => ({
        url: `/user/tokens_dif/${credentials.userId}`,
        method: "PATCH",
        body: { tokens: credentials.tokens },
      }),
    }),
    levelUpUser: builder.mutation({
      query: (credentials) => ({
        url: "/user/levelup",
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    levelUpUserDif: builder.mutation({
      query: (credentials) => ({
        url: `/user/levelup_dif/${credentials.userId}`,
        method: "PATCH",
        body: { exp: credentials.exp },
      }),
    }),
    incrementCount: builder.mutation({
      query: (credentials) => ({
        url: "/user/increment",
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    reduceTokens: builder.mutation({
      query: (credentials) => ({
        url: "/user/reduce",
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/user/admindel/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useGetAllUsersQuery,
  useUpdateUserInfoMutation,
  useAddUserTokensMutation,
  useAddUserTokensDifMutation,
  useLevelUpUserMutation,
  useLevelUpUserDifMutation,
  useIncrementCountMutation,
  useReduceTokensMutation,
  useDeleteUserMutation,
} = userApiSlice;
