import React from "react";
import { useDeleteUserMutation } from "../../store/user/userApiSlice";

const UserSingle = ({ id, number, name, login, ip }) => {
  const [deleteUser] = useDeleteUserMutation();

  const delAccount = () => {
    const resp = deleteUser(id);
    if (resp.data) {
      window.location.reload();
    }
  };

  return (
    <>
      {login !== "rocketcoin_admin" && (
        <div className="admin-user">
          <p>{number + 1}</p>
          <p className="admin-user-name">
            {name} ({login})
          </p>
          <p className="admin-user-ip">{ip}</p>
          <button className="admin-user-del" onClick={delAccount}>
            Удалить
          </button>
        </div>
      )}
    </>
  );
};

export default UserSingle;
