const generateChallengeArray = (data, lang) => {
  let challengeArray = [
    {
      id: 0,
      title: lang === "en" ? "Complete 80 links" : "Завершить 80 ссылок",
      completed: data?.linksDayCount,
      toComplete: 80,
      tokens: 600,
      exp: 40,
      isDisabled: data?.linksChallengesClaimed >= 4 ? true : false,
    },
    {
      id: 1,
      title: lang === "en" ? "Complete 50 links" : "Завершить 50 ссылок",
      completed: data?.linksDayCount,
      toComplete: 50,
      tokens: 300,
      exp: 25,
      isDisabled: data?.linksChallengesClaimed >= 3 ? true : false,
    },
    {
      id: 2,
      title: lang === "en" ? "Complete 10 links" : "Завершить 10 ссылок",
      completed: data?.linksDayCount,
      toComplete: 10,
      tokens: 33,
      exp: 10,
      isDisabled: data?.linksChallengesClaimed >= 2 ? true : false,
    },
    {
      id: 3,
      title: lang === "en" ? "Complete 25 links" : "Завершить 25 ссылок",
      completed: data?.linksDayCount,
      toComplete: 25,
      tokens: 66,
      exp: 15,
      isDisabled: data?.linksChallengesClaimed >= 1 ? true : false,
    },
    {
      id: 4,
      title: lang === "en" ? "Complete 100 faucet" : "Завершить 100 faucet",
      completed: data?.faucetDayCount,
      toComplete: 100,
      tokens: 50,
      exp: 25,
      isDisabled: data?.faucetChallengesClaimed >= 4 ? true : false,
    },
    {
      id: 5,
      title: lang === "en" ? "Complete 50 faucet" : "Завершить 50 faucet",
      completed: data?.faucetDayCount,
      toComplete: 50,
      tokens: 25,
      exp: 15,
      isDisabled: data?.faucetChallengesClaimed >= 3 ? true : false,
    },
    {
      id: 6,
      title: lang === "en" ? "Complete 30 faucet" : "Завершить 30 faucet",
      completed: data?.faucetDayCount,
      toComplete: 30,
      tokens: 15,
      exp: 10,
      isDisabled: data?.faucetChallengesClaimed >= 2 ? true : false,
    },
    {
      id: 7,
      title: lang === "en" ? "Complete 10 faucet" : "Завершить 10 faucet",
      completed: data?.faucetDayCount,
      toComplete: 10,
      tokens: 5,
      exp: 5,
      isDisabled: data?.faucetChallengesClaimed >= 1 ? true : false,
    },
    {
      id: 8,
      title: lang === "en" ? "Complete 50 PTC" : "Завершить 50 PTC",
      completed: data?.ptcDayCount,
      toComplete: 50,
      tokens: 50,
      exp: 15,
      isDisabled: data?.ptcChallengesClaimed >= 3 ? true : false,
    },
    {
      id: 9,
      title: lang === "en" ? "Complete 25 PTC" : "Завершить 25 PTC",
      completed: data?.ptcDayCount,
      toComplete: 25,
      tokens: 25,
      exp: 10,
      isDisabled: data?.ptcChallengesClaimed >= 2 ? true : false,
    },
    {
      id: 10,
      title: lang === "en" ? "Complete 10 PTC" : "Завершить 10 PTC",
      completed: data?.ptcDayCount,
      toComplete: 10,
      tokens: 10,
      exp: 3,
      isDisabled: data?.ptcChallengesClaimed >= 1 ? true : false,
    },
    {
      id: 11,
      title:
        lang === "en" ? "3000 tokens with Offers" : "3000 токенов на Офферах",
      completed: data?.offerwallDayCount,
      toComplete: 3000,
      tokens: 150,
      exp: 100,
      isDisabled: data?.offerwallChallengesClaimed >= 3 ? true : false,
    },
    {
      id: 12,
      title:
        lang === "en" ? "2000 tokens with Offers" : "2000 токенов на Офферах",
      completed: data?.offerwallDayCount,
      toComplete: 2000,
      tokens: 45,
      exp: 30,
      isDisabled: data?.offerwallChallengesClaimed >= 2 ? true : false,
    },
    {
      id: 13,
      title:
        lang === "en" ? "500 tokens with Offers" : "500 токенов на Офферах",
      completed: data?.offerwallDayCount,
      toComplete: 500,
      tokens: 15,
      exp: 10,
      isDisabled: data?.offerwallChallengesClaimed >= 1 ? true : false,
    },
  ];

  // return challengeArray;
  return {
    firstArray: challengeArray.slice(0, 2),
    secondArray: challengeArray.slice(2, 4),
    thirdArray: challengeArray.slice(4, 7),
    fourthArray: challengeArray.slice(7, 10),
    fifthArray: challengeArray.slice(10, 14),
  };
};
export default generateChallengeArray;
