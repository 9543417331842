const accordionArray = [
  {
    id: 0,
    title: "Can I use Bot/ VPN/ Proxy?",
    text: "Bots, VPN/Proxy are not allowed on this site. We will ban all violated accounts.",
  },
  {
    id: 1,
    title: "Can I invite my friends?",
    text: "We share up to 10% of your friend earning for referring them. You can get your referral link in Refferal page.",
  },
  {
    id: 2,
    title: "Can I create multiple accounts?",
    text: "You are not allowed to create multiple accounts or have multiple accounts in the same network. All violated accounts will be banned.",
  },
  {
    id: 3,
    title: "What is the minimum to withdraw?",
    text: "500 tokens",
  },
  {
    id: 4,
    title: "How do shortlinks complete?",
    text: "link",
  },
  {
    id: 5,
    title: "Where do I get paid?",
    text: "We currently support withdrawals to  FaucetPay microwallet.",
  },
];
export default accordionArray;
