import React from "react";
import Layout from "../layout/Layout";
import SideBar from "../shared/SideBar";
import LinkItem from "../shared/LinkItem";
import { useGetLinksInfoQuery } from "../../store/links/linksApiSlice";
import generateLinksArray from "../../utils/generateLinksArray";
import { useSelector } from "react-redux";
import AdblockModal from "../shared/AdblockModal";
import { useGetUserInfoQuery } from "../../store/user/userApiSlice";
import RotationBanner from "../shared/RotationBanner";
import FixedBanner from "../shared/FixedBanner";
import MobileBanner from "../shared/MobileBanner";
import Banner2 from "../shared/Banner2";

const Links = () => {
  const userData = useGetUserInfoQuery();

  const { data } = useGetLinksInfoQuery(userData?.data?.registrationIp);
  const { curLang } = useSelector((state) => state.general);

  const {
    firstArray,
    secondArray,
    thirdArray,
    fourthArray,
    fifthArray,
    sixthArray,
    seventhArray,
  } = generateLinksArray(data);

  return (
    <main>
      <Layout
        title={`Rocketcoin - ${curLang === "en" ? "Shortlinks" : "Ссылки"}`}
      >
        <section className="content-lk">
          <SideBar />
          <div className="right-content-lk">
            <div className="page-title-ban-cont">
              <h1 className="title-page-lk">
                {curLang === "en" ? "Shortlinks" : "Ссылки"}
              </h1>
              <div className="banner banner728 banner-profile">
                <iframe
                  title="banner"
                  data-aa="2284954"
                  src="//ad.a-ads.com/2284954?size=728x90"
                ></iframe>
              </div>
            </div>
            <div className="wrapper-page-lk">
              <div className="content-block-flex-lk-white">
                <div className="task-offers-content">
                  {firstArray?.map((item, index) => {
                    const {
                      disabled,
                      link,
                      reward,
                      claimed,
                      claimOut,
                      linkName,
                    } = item;
                    return (
                      <LinkItem
                        key={index}
                        disabled={disabled}
                        link={link}
                        reward={reward}
                        claimed={claimed}
                        claimOut={claimOut}
                        linkName={linkName}
                      />
                    );
                  })}
                  <div className="promotion-blocks">
                    <div className="promotion-block block300 desktop-banner">
                      <RotationBanner
                        width={300}
                        datasid="385170"
                        datakey="74f5fd8cb2210ae903b1c609be6726ea"
                        dataframe="2259313"
                      />
                    </div>
                    <div className="promotion-block block300 desktop-banner">
                      <RotationBanner
                        width={300}
                        datasid="385171"
                        datakey="74f5fd8cb2210ae903b1c609be6726ea"
                        dataframe="2259314"
                      />
                    </div>
                    <div className="promotion-block block300 desktop-banner">
                      <RotationBanner
                        width={300}
                        datasid="385172"
                        datakey="74f5fd8cb2210ae903b1c609be6726ea"
                        dataframe="2259316"
                      />
                    </div>
                  </div>
                  <div className="promotion-blocks blocks-nonflex">
                    <div className="promotion-block banner728 mobile-banner">
                      <MobileBanner
                        width={728}
                        datasid="385065"
                        dataframe="2284906"
                        count={2}
                      />
                    </div>
                    <div className="promotion-block banner728 mobile-banner">
                      <MobileBanner
                        width={728}
                        datasid="385066"
                        dataframe="2284906"
                        count={2}
                      />
                    </div>
                  </div>
                  {secondArray?.map((item, index) => {
                    const {
                      disabled,
                      link,
                      reward,
                      claimed,
                      claimOut,
                      linkName,
                    } = item;
                    return (
                      <LinkItem
                        key={index}
                        disabled={disabled}
                        link={link}
                        reward={reward}
                        claimed={claimed}
                        claimOut={claimOut}
                        linkName={linkName}
                      />
                    );
                  })}
                  {/* <div className="promotion-blocks">
                    <div className="promotion-block block300 desktop-banner">
                      <RotationBanner
                        width={300}
                        datasid="383837"
                        datakey="74f5fd8cb2210ae903b1c609be6726ea"
                        dataframe="2259319"
                      />
                    </div>
                    <div className="promotion-block block300 desktop-banner">
                      <RotationBanner
                        width={300}
                        datasid="383838"
                        datakey="74f5fd8cb2210ae903b1c609be6726ea"
                        dataframe="2259320"
                      />
                    </div>
                    <div className="promotion-block block300 desktop-banner">
                      <RotationBanner
                        width={300}
                        datasid="383839"
                        datakey="74f5fd8cb2210ae903b1c609be6726ea"
                        dataframe="2260450"
                      />
                    </div>
                    <div className="promotion-blocks blocks-nonflex">
                      <div className="promotion-block banner728 mobile-banner">
                        <MobileBanner
                          width={728}
                          datasid="385067"
                          dataframe="2284906"
                          count={2}
                        />
                      </div>
                      <div className="promotion-block banner728 mobile-banner">
                        <MobileBanner
                          width={728}
                          datasid="385068"
                          dataframe="2284906"
                          count={2}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="promotion-blocks blocks-nonflex">
                    <div className="promotion-block banner728 mobile-banner">
                      <MobileBanner
                        width={728}
                        datasid="385065"
                        dataframe="2284906"
                        count={2}
                      />
                    </div>
                    <div className="promotion-block banner728 mobile-banner">
                      <MobileBanner
                        width={728}
                        datasid="385066"
                        dataframe="2284906"
                        count={2}
                      />
                    </div>
                  </div>
                  {thirdArray?.map((item, index) => {
                    const {
                      disabled,
                      link,
                      reward,
                      claimed,
                      claimOut,
                      linkName,
                    } = item;
                    return (
                      <LinkItem
                        key={index}
                        disabled={disabled}
                        link={link}
                        reward={reward}
                        claimed={claimed}
                        claimOut={claimOut}
                        linkName={linkName}
                      />
                    );
                  })}
                  <div className="banner banner728 banner-profile banner-links">
                    <Banner2 data={385173} />
                  </div>
                  {fourthArray?.map((item, index) => {
                    const {
                      disabled,
                      link,
                      reward,
                      claimed,
                      claimOut,
                      linkName,
                    } = item;
                    return (
                      <LinkItem
                        key={index}
                        disabled={disabled}
                        link={link}
                        reward={reward}
                        claimed={claimed}
                        claimOut={claimOut}
                        linkName={linkName}
                      />
                    );
                  })}
                  <div className="banner banner468 banner-profile banner-links">
                    <Banner2 data={385175} />
                  </div>
                  {fifthArray?.map((item, index) => {
                    const {
                      disabled,
                      link,
                      reward,
                      claimed,
                      claimOut,
                      linkName,
                    } = item;
                    return (
                      <LinkItem
                        key={index}
                        disabled={disabled}
                        link={link}
                        reward={reward}
                        claimed={claimed}
                        claimOut={claimOut}
                        linkName={linkName}
                      />
                    );
                  })}
                  <div className="banner banner728 banner-profile banner-links">
                    <Banner2 data={385174} />
                  </div>
                  {sixthArray?.map((item, index) => {
                    const {
                      disabled,
                      link,
                      reward,
                      claimed,
                      claimOut,
                      linkName,
                    } = item;
                    return (
                      <LinkItem
                        key={index}
                        disabled={disabled}
                        link={link}
                        reward={reward}
                        claimed={claimed}
                        claimOut={claimOut}
                        linkName={linkName}
                      />
                    );
                  })}
                  <div className="banner banner728 banner-profile banner-links">
                    <Banner2 data={385176} />
                  </div>
                  {seventhArray?.map((item, index) => {
                    const {
                      disabled,
                      link,
                      reward,
                      claimed,
                      claimOut,
                      linkName,
                    } = item;
                    return (
                      <LinkItem
                        key={index}
                        disabled={disabled}
                        link={link}
                        reward={reward}
                        claimed={claimed}
                        claimOut={claimOut}
                        linkName={linkName}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="bottom-banners-cont">
                <div className="banner banner728 banner-profile">
                  <Banner2 data={385176} />
                </div>
                <div className="banner banner468 banner-profile">
                  <Banner2 data={385178} />
                </div>
              </div>
            </div>
            <div className="bottom-banners-cont">
              <div className="promotion-block banner728 mobile-banner">
                <MobileBanner
                  width={728}
                  datasid="385072"
                  dataframe="2284906"
                  count={2}
                />
              </div>
            </div>
          </div>
        </section>
        <FixedBanner page="links" />
        <AdblockModal />
      </Layout>
    </main>
  );
};

export default Links;
