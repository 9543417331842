import React, { useEffect, useState } from "react";
import Banner2 from "./Banner2";

const MobileBanner = ({ width, datasid, datakey, dataframe, count }) => {
  const [curBanner, setCurBanner] = useState(0);

  useEffect(() => {
    const getRand = (max) => {
      const num = Math.floor(Math.random() * max);
      setCurBanner(num);
    };
    getRand(2);
  }, []);

  return (
    <>
      {curBanner === 0 ? (
        <iframe
          title="home banner"
          data-aa={dataframe}
          src={`//ad.a-ads.com/${dataframe}?size=${
            width === 728 ? "728x90" : width === 300 ? "300x250" : ""
          }`}
          className="mobile-banner"
        ></iframe>
      ) : (
        <Banner2 data={datasid} type="mobile" />
      )}
    </>
  );
};

export default MobileBanner;
