import React, { useState } from "react";
import SideBarAdmin from "../../shared/SideBarAdmin";
import Layout from "../../layout/Layout";
import { useGetAllUsersQuery } from "../../../store/user/userApiSlice";
import UserSingle from "../../shared/UserSingle";

const UsersAdmin = () => {
  const [take, setTake] = useState(20);
  const [isBtnShown, setIsBtnShown] = useState(true);
  const { data } = useGetAllUsersQuery(take);

  const loadMore = () => {
    if (data.length < take + 5) {
      setIsBtnShown(false);
    }
    setTake(take + 5);
  };

  return (
    <main>
      <Layout title="Rocketcoin - Пользователи">
        <section className="content-lk">
          <a href="#!" className="btn-open-modal-panel-lk">
            Меню кабинета
          </a>
          <SideBarAdmin />
          <div class="right-content-lk">
            <div className="title-btn-cont">
              <h1 className="title-page-lk">Пользователи -- Админ</h1>
            </div>
            <div class="wrapper-page-lk">
              <div class="challenge-lk-content">
                <div className="users-cont">
                  {data?.map((user, index) => {
                    const { id, registrationIp, name, login } = user;
                    return (
                      <UserSingle
                        key={id}
                        id={id}
                        number={index}
                        name={name}
                        login={login}
                        ip={registrationIp}
                      />
                    );
                  })}
                </div>
                {isBtnShown && (
                  <button className="users-load-more" onClick={loadMore}>
                    Загрузить еще
                  </button>
                )}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </main>
  );
};

export default UsersAdmin;
