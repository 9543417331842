const generateBonusArray = (amount, levelTokens, earningBonus, curLevel) => {
  let levelsArray = [];
  for (let i = 1; i <= amount; i++) {
    const level = {
      number: curLevel + i,
      levelTokens: 10 + (5 * (curLevel + i - 1)),
      earningBonus,
    };
    levelsArray.push(level);
  }
  return levelsArray;
};
export default generateBonusArray;
