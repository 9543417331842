import React, { useEffect, useRef } from "react";

const Popunder2 = () => {
  const banner = useRef();

  useEffect(() => {
    if (!banner.current.firstChild) {
      const script = document.createElement("script");

      script.src = `https://pl20535388.highcpmrevenuegate.com/ef/2e/25/ef2e259d56a22e589fc32500806f0c68.js`;

      if (banner.current) {
        if (window.screen.width > 550) banner.current.append(script);
      }
    }
  }, []);

  return <div ref={banner}></div>;
};

export default Popunder2;
