import React from "react";
import Layout from "../layout/Layout";
import SideBar from "../shared/SideBar";
import generateChallengeArray from "../../utils/generateChallengeArray";
import { useGetUserInfoQuery } from "../../store/user/userApiSlice";
import ChallengeItem from "../shared/ChallengeItem";
import { useSelector } from "react-redux";
import FixedBanner from "../shared/FixedBanner";
import Popunder from "../shared/Popunder";
import Banner2 from "../shared/Banner2";
import MobileBanner from "../shared/MobileBanner";

const Challenge = () => {
  const { data } = useGetUserInfoQuery();
  const { curLang } = useSelector((state) => state.general);

  const { firstArray, secondArray, thirdArray, fourthArray, fifthArray } =
    generateChallengeArray(data, curLang);

  return (
    <main>
      <Layout
        title={`Rocketcoin - ${curLang === "en" ? "Challenge" : "Челлендж"}`}
      >
        <section className="content-lk">
          <SideBar />
          <div class="right-content-lk">
            <div className="page-title-ban-cont">
              <h1 class="title-page-lk">
                {curLang === "en" ? "Challenge" : "Челлендж"}
              </h1>
              <div className="banner banner728 banner-profile desktop-banner">
                <iframe
                  title="banner"
                  data-aa="2284928"
                  src="//ad.a-ads.com/2284928?size=728x90"
                ></iframe>
              </div>
              <div className="banner banner468 banner-profile desktop-banner">
                <iframe
                  title="banner"
                  data-aa="2284927"
                  src="//ad.a-ads.com/2284927?size=468x60"
                ></iframe>
              </div>
              <div className="banner banner468 banner-profile mobile-banner">
                <MobileBanner
                  width={468}
                  datasid="385085"
                  dataframe="2284927"
                  count={2}
                />
              </div>
            </div>
            <div class="wrapper-page-lk">
              <div class="challenge-lk-content leaderboard-container">
                {firstArray.map((item) => {
                  const {
                    id,
                    title,
                    completed,
                    toComplete,
                    tokens,
                    exp,
                    isDisabled,
                  } = item;
                  return (
                    <ChallengeItem
                      key={id}
                      id={id}
                      title={title}
                      completed={completed}
                      toComplete={toComplete}
                      tokens={tokens}
                      exp={exp}
                      isDisabled={isDisabled}
                    />
                  );
                })}
                <div className="challange-banners-cont">
                  <div className="banner banner728 banner-profile desktop-banner">
                    <iframe
                      title="banner"
                      data-aa="2284928"
                      src="//ad.a-ads.com/2284928?size=728x90"
                    ></iframe>
                  </div>
                  <div className="banner banner728 banner-profile desktop-banner">
                    <Banner2 data={385195} />
                  </div>
                  <div className="banner banner728 banner-profile mobile-banner">
                    <MobileBanner
                      width={728}
                      datasid="385086"
                      dataframe="2284928"
                      count={2}
                    />
                  </div>
                </div>
                {secondArray.map((item) => {
                  const {
                    id,
                    title,
                    completed,
                    toComplete,
                    tokens,
                    exp,
                    isDisabled,
                  } = item;
                  return (
                    <ChallengeItem
                      key={id}
                      id={id}
                      title={title}
                      completed={completed}
                      toComplete={toComplete}
                      tokens={tokens}
                      exp={exp}
                      isDisabled={isDisabled}
                    />
                  );
                })}
                <div className="challange-banners-cont desktop-banner">
                  <div className="banner banner728 banner-profile">
                    <Banner2 data={385196} />
                  </div>
                  <div className="banner banner728 banner-profile">
                    <Banner2 data={385197} />
                  </div>
                </div>
                <div className="banner banner728 banner-profile mobile-banner">
                  <MobileBanner
                    width={728}
                    datasid="385087"
                    dataframe="2284928"
                    count={2}
                  />
                </div>
                {thirdArray.map((item) => {
                  const {
                    id,
                    title,
                    completed,
                    toComplete,
                    tokens,
                    exp,
                    isDisabled,
                  } = item;
                  return (
                    <ChallengeItem
                      key={id}
                      id={id}
                      title={title}
                      completed={completed}
                      toComplete={toComplete}
                      tokens={tokens}
                      exp={exp}
                      isDisabled={isDisabled}
                    />
                  );
                })}
                <div className="banner banner728 banner-profile mobile-banner">
                  <Banner2 data={385088} />
                </div>
                {fourthArray.map((item) => {
                  const {
                    id,
                    title,
                    completed,
                    toComplete,
                    tokens,
                    exp,
                    isDisabled,
                  } = item;
                  return (
                    <ChallengeItem
                      key={id}
                      id={id}
                      title={title}
                      completed={completed}
                      toComplete={toComplete}
                      tokens={tokens}
                      exp={exp}
                      isDisabled={isDisabled}
                    />
                  );
                })}
                <div className="banner banner728 banner-profile mobile-banner">
                  <Banner2 data={385089} />
                </div>
                {fifthArray.map((item) => {
                  const {
                    id,
                    title,
                    completed,
                    toComplete,
                    tokens,
                    exp,
                    isDisabled,
                  } = item;
                  return (
                    <ChallengeItem
                      key={id}
                      id={id}
                      title={title}
                      completed={completed}
                      toComplete={toComplete}
                      tokens={tokens}
                      exp={exp}
                      isDisabled={isDisabled}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="banner banner728 banner-profile mobile-banner">
            <Banner2 data={385090} />
          </div>
        </section>
        <FixedBanner page="challenge" />
        <Popunder />
      </Layout>
    </main>
  );
};

export default Challenge;
